import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { 
    path: '/home', 
    name:'home',
    component : () => import('@/views/home'),
  },
  { 
    path: '/', 
    redirect:'/home',
  },
  { 
    path: '/products', 
    name:'products',
    component : () => import('@/views/products'),
    children:[
      {
        path: '/core', 
        name:'core',
        component : () => import('@/views/products/core.vue'),
      },
      {
        path: '/check', 
        name:'check',
        component : () => import('@/views/products/check.vue'),
      },
      {
        path: '/withhold', 
        name:'withhold',
        component : () => import('../views/products/withhold.vue'),
      },
      {
        path: '/marketing', 
        name:'marketing',
        component : () => import('../views/products/marketing.vue'),
      },
    ]
  },
  { 
    path: '/news', 
    name:'news',
    component : () => import('@/views/news'),
  },
  { 
    path: '/news/:id', 
    name:'newsDetail',
    component : () => import('@/views/newsDetail.vue'),
    props: true 
  },
  { 
    path: '/about', 
    name:'about',
    component : () => import('@/views/about'),
  },
  { 
    path: '/join', 
    name:'join',
    component : () => import('@/views/join'),
  },
  { 
    path: '/header', 
    name:'header',
    component : () => import('@/components/header.vue'),
  },
  { 
    path: '/footer', 
    name:'footer',
    component : () => import('@/components/footer.vue'),
  },

]

const router = new VueRouter({
  routes
})

export default router