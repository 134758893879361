<template>
  <div class="footer">
    <div class="footer-1">
      <div class="top">
        <div class="img"><img src="../assets/img/logo.png" alt=""></div>
        <div class="text">数动未来</div>
      </div>
      <div class="middle">Copyright © 杭州数动未来科技有限公司，未经许可不得复制、转载或摘编
      </div>
      <div class="bottom">
        <div class="bottom-l">
          <div class="img"><img src="../assets/img/footer1.png" alt=""></div>
          <div class="text">浙ICP备11010502000501号</div>
        </div>
        <div class="bottom-r">
          <div class="img"><img src="../assets/img/footer2.png" alt=""></div>
          <div class="text">浙网警安网备案45890000号</div>
        </div>
      </div>
    </div>

    <div class="footer-2">
      <div class="title">联系我们</div>
      <ul>
        <li>杭州市滨江区江南大道3888号信雅达大厦4楼</li>
        <li>联系人: 陈小姐</li>
        <li>邮箱: cjy@myouchai.com</li>
        <li>联系电话: 0571-56686500</li>
      </ul>
    </div>
    <div class="footer-3">
      <div class="title">产品和解决方案</div>
      <div class="line-1"><router-link to="/core">核心产品 -- 邮差平台 (DSB) </router-link></div>
      <div class="line-2">
        <div class="line-l">应用中心</div>
        <div class="line-r">
          <ul>
            <li><router-link to="/check">-- 实时核对中心 (MPC) </router-link></li>
            <li><router-link to="/withhold">-- 实时代扣中心 (DED) </router-link></li>
            <li><router-link to="/marketing">-- 营销事件中心 (EC) </router-link></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer-4">
      <div class="title">关于我们</div>
      <ul>
        <li><router-link to="/about">公司介绍</router-link></li>
        <li><router-link to="/about">创始人团队</router-link></li>
        <li><router-link to="/about">合作机构</router-link></li>
        <li><router-link to="/about">资质</router-link></li>
        <li><router-link to="/join">加入我们</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  background: #142449;
  padding: 40px 100px 20px 100px;
  box-sizing: border-box;
  font-size: 14px;
  color: #FFFFFF;
  display: flex;
  row-gap: 48px;
  flex-wrap: wrap;
  gap: 48px;

  .footer-1 {
      line-height: 20px;

    .top {
      display: flex;
      margin-bottom: 30px;

      .img {
        width: 30px;
        height: 29px;
        margin-right: 13px;
      }

      .text {
        font-size: 19px;
        line-height: 30px;
      }
    }

    .bottom {
      display: flex;
      height: 30px;
      margin-top: 30px;

      .bottom-l {
        margin-right: 20px;
        display: flex;

        .img {
          margin-right: 4px;
        }

        .text {
          line-height: 25px;
        }
      }

      .bottom-r {
        display: flex;

        .img {
          margin-right: 4px;
        }

        .text {
          line-height: 25px;
        }
      }
    }
  }

  .footer-2 {

    li {
      margin-top: 30px;
    }
  }

  .footer-3 {
    .line-1 {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .line-2 {
      display: flex;

      .line-l {
        width: 20%;
      }

      .line-r {
        width: 80%;

        li {
          margin-bottom: 20px;
        }
      }
    }
  }

  .footer-4 {

    li {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  a {
    color: #FFFFFF;
    text-decoration: none;
  }

  .router-link-active {
    color: #FFFFFF;
    text-decoration: none;
  }

  .title {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
@media  (max-width: 415px) {
  .footer {
    padding: 16px;
    flex-direction: column;
    .footer-1 {
      width: fit-content;
    }
    .footer-2 {
      width: fit-content;
    }
    .footer-3 {
      width: fit-content;
    }
    .footer-4 {
      width: fit-content;
    }
  }
}
</style>