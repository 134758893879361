<template>
  <div id="app">
    <!-- 头部 -->
    <components-header></components-header>
      <router-view/>

    <!-- 底部 -->
    <components-footer></components-footer>
  </div>
</template>

<script>
import ComponentsHeader from '@/components/header.vue'
import ComponentsFooter from '@/components/footer.vue'

export default {
  name:'App',
  components: {
    ComponentsHeader,
    ComponentsFooter
  }
}
</script>

<style lang="less">
  @import url('./assets/css/reset.css');
  body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
</style>
