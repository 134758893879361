<template>
  <div class="header">
    <div class="header-left">
      <div class="img"><img src="../assets/img/logo.png" alt=""></div>
      <div class="text">数动未来</div>
    </div>
    <div class="header-right">
      <el-menu 
          :default-active="$route.path" 
          class="el-menu-demo" 
          mode="horizontal" 
          @select="handleSelect"
          background-color="#142449" 
          text-color="#FFFFFF" 
          active-text-color="#FFFFFF"
          router="true"
      >
        <el-menu-item index="/home">首页</el-menu-item>
        <el-submenu index="2">
          <template slot="title">产品和解决方案</template>
          <el-submenu index="2-1">
            <template slot="title">核心产品</template>
            <el-menu-item index="/core">邮差平台 (DSB)</el-menu-item>
          </el-submenu>
          <el-submenu index="2-2">
            <template slot="title">应用中心</template>
            <el-menu-item index="/check">实时核对中心 (MPC)</el-menu-item>
            <el-menu-item index="/withhold">实时代扣中心 (DED)</el-menu-item>
            <el-menu-item index="/marketing">营销事件中心 (EC)</el-menu-item>
          </el-submenu>
        </el-submenu>
        <el-menu-item index="/news">新闻动态</el-menu-item>
        <el-menu-item index="/about">关于我们</el-menu-item>
        <el-menu-item index="/join">加入我们</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    
    };
  },
  methods: {
    handleSelect(path) {
      this.$router.push({
        path: path
      }).catch(err => { console.log(err) })
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 60px;
  background: #142449;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  display: flex;
  position: fixed;
  top: 0;
  z-index: 9;


  .header-left {
    height: 60px;
    width: 40%;
    display: flex;

    .img {
      width: 30px;
      height: 29px;
      margin: 16px 13px 0 122px;
    }

    .text {
      font-size: 19px;
      font-weight: 600;
      line-height: 60px;
    }
  }

  .header-right {
    // flex: 1;
    line-height: 60px;

    .el-menu{
      height: 58px;
    }
  }
}
</style>